<template>
  <div class="blocks-main-container">

    <div v-if="blocks.length">
      <div class="block-cards">
        <div
          v-for="(block, index) in blocks"
          :key="index"
          :class="['block-card']"
        >
          <h4>{{ block.title }}</h4>
          <p><strong>Key:</strong> {{ block.key }}</p>
          <p v-if="block.workflows && block.workflows.length > 0"><strong>Workflows:</strong> {{ block.workflows.join(', ') }}</p>
          <b-icon icon="pencil" variant="warning" class="edit-icon" @click="editBlock(block)" />
          <b-icon icon="trash" variant="danger" class="delete-icon" @click="deleteBlock(block._id)" />
        </div>
      </div>
    </div>
    <div v-else>
      <p>Blocks not found</p>
    </div>

    <b-button variant="primary" @click="showAddForm">Add Block</b-button>

    <b-modal v-model="showForm" title="Add Block" hide-footer @hidden="resetForm">
      <b-form @submit.prevent="saveBlock">
        <b-form-group label="Block Type:">
          <b-form-radio-group v-model="block.key" :options="getBlocksOptions()"></b-form-radio-group>
        </b-form-group>

        <b-form-group v-if="block.key" label="Title:">
          <CodeMirrorComponent v-model="block.title" :options="codeMirrorOptions" />
        </b-form-group>

        <b-form-group v-if="block.key" label="Description:">
          <CodeMirrorComponent v-model="block.description" :options="codeMirrorOptions" />
        </b-form-group>

        <div v-if="block.key === 'ai_block'" class="block-content">
          <b-form-group label="Key:" v-if="block.content.mode === 'one_key_ai'">
            <CodeMirrorComponent v-model="block.content.key" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Value:">
            <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Mode:">
            <b-form-radio v-model="block.content.mode" value="one_key_ai">One Key AI</b-form-radio>
            <b-form-radio v-model="block.content.mode" value="full_data_ai">Full Data AI</b-form-radio>
          </b-form-group>
          <b-form-group label="Model:">
            <b-form-select v-model="block.content.model" :options="['llama', 'gemma']"></b-form-select>
          </b-form-group>
          <b-form-group label="System Prompt:">
            <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.system_prompt" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Temperature:">
            <b-form-input class="border-top border-left border-right border-bottom rounded-0 bg-light mt-1 p-2" v-model="block.content.temperature" type="number" min="0" max="1" step="0.1"></b-form-input>
          </b-form-group>
        </div>

        <div v-if="block.key === 'code_block'" class="block-content">
          <b-form-group label="Value:">
            <CodeMirrorComponent class="codemirror-textarea" v-model="block.content.value" :options="codeMirrorOptions" />
          </b-form-group>
        </div>

        <div v-if="block.key === 'field_block'" class="block-content">
          <b-form-group label="Key:">
            <CodeMirrorComponent v-model="block.content.key" :options="codeMirrorOptions" />
          </b-form-group>
          <b-form-group label="Value:">
            <CodeMirrorComponent v-model="block.content.value" :options="codeMirrorOptions" />
          </b-form-group>
        </div>

        <b-button type="submit" variant="primary">{{ editMode ? 'Update' : 'Save' }}</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import CodeMirrorComponent from '@/agGridV2/components/codemirror.component.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  name: 'lead-center-blocks-library',
  components: {
    CodeMirrorComponent
  },
  data() {
    return {
      showForm: false,
      block: {},
      blocks: [],
      codeMirrorOptions: {
        lineWrapping: true
      },
      editMode: false
    }
  },
  async mounted() {
    this.setViewLoader(true)
    this.blocks = await MtApi.getBlocks()
    this.setViewLoader(false)
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    newBlock() {
      return {
        key: '',
        title: '',
        description: '',
        content: {
          key: '',
          value: '',
          mode: '',
          model: '',
          system_prompt: '',
          temperature: 0.5
        }
      }
    },
    showAddForm() {
      this.resetForm()
      this.showForm = true
    },
    resetForm() {
      this.block = this.newBlock()
      this.editMode = false
    },
    getBlocksOptions() {
      return [
        {
          text: 'AI Block',
          value: 'ai_block'
        },
        {
          text: 'Code Block',
          value: 'code_block'
        },
        {
          text: 'Field Block',
          value: 'field_block'
        }
      ]
    },
    async saveBlock() {
      const exists = this.blocks.some((block) => block.title === this.block.title && block._id !== this.block._id)
      if (exists) {
        this.errorMessage = 'Block with this title already exists. Please enter a different title.'
        return
      }

      if (this.editMode) {
        const index = this.blocks.findIndex((block) => block._id === this.block._id)
        if (index !== -1) {
          await MtApi.createOrUpdateBlock(this.block)
          this.blocks.splice(index, 1, { ...this.block })
        }
      } else {
        const newBlock = { ...this.block }
        delete newBlock._id
        const block = await MtApi.createOrUpdateBlock(newBlock)
        this.blocks.push(block)
      }

      this.resetForm()
      this.showForm = false
    },
    editBlock(block) {
      this.block = { ...block }
      this.editMode = true
      this.showForm = true
    },
    deleteBlock(_id) {
      this.$dialog
        .confirm('<img style="width: 100%; margin-bottom: 20px;" src="/uncle_sam.webp" />Are you sure??? This will permanently delete block', {
          html: true,
          cancelText: 'Close',
          okText: 'I\'m sure',
          clicksCount: 10,
          backdropClose: true,
          type: 'soft'
        })
      .then(async(dialog) => {
          await MtApi.deleteBlock(_id)
          this.blocks = this.blocks.filter((block) => block._id !== _id)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .codemirror .cm-editor {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  cursor: text;
}
.form-group {
  padding: 0;
}
.block-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.block-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  width: calc(33.333% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: opacity 0.3s ease;
}

.edit-icon, .delete-icon {
  position: absolute;
  top: 10px;
  cursor: pointer;
  font-size: 1.5em;
}

.edit-icon {
  right: 40px;
}

.delete-icon {
  right: 10px;
}
</style>